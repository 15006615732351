<template>
  <app-form-select
    v-model="model"
    item-title="label"
    item-value="value"
    :items="items"
    label="Language"
    :readonly="readonly"
    :required="required"
    :return-object="false"
  />
</template>

<script lang="ts" setup>
const model = defineModel({ default: undefined });

defineProps<{
  required?: boolean;
  readonly?: boolean;
}>();

const items = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Français",
    value: "fr",
  },
];
</script>

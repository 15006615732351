<template>
  <v-combobox v-bind="bind" v-model="value" :items="items" />
</template>

<script lang="ts" setup>
const properties = defineProps({
  ...appFormInputProperties,
  items: { type: Array, default: undefined },
});

const { value, bind } = useAppFormInput(properties);
</script>
